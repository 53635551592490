import React from "react";
import PastMusic from "../PastMusic";
import Footer from "../Footer";

export default function Music() {
	return (
		<>
			<PastMusic />
			<Footer />
		</>
	);
}
